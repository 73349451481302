@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Regular/GT-Walsheim-Regular.woff2')
      format('woff2'),
    url('../fonts/GT-Walsheim-Regular/GT-Walsheim-Regular.woff') format('woff'),
    url('../fonts/GT-Walsheim-Regular/GT-Walsheim-Regular.ttf')
      format('truetype'),
    url('../fonts/GT-Walsheim-Regular/GT-Walsheim-Regular.eot?#iefix')
      format('embedded-opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Regular-Oblique/GT-Walsheim-Regular-Oblique.woff2')
      format('woff2'),
    url('../fonts/GT-Walsheim-Regular-Oblique/GT-Walsheim-Regular-Oblique.woff')
      format('woff'),
    url('../fonts/GT-Walsheim-Regular-Oblique/GT-Walsheim-Regular-Oblique.ttf')
      format('truetype'),
    url('../fonts/GT-Walsheim-Regular-Oblique/GT-Walsheim-Regular-Oblique.eot?#iefix')
      format('embedded-opentype');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Medium/GT-Walsheim-Medium.woff2')
      format('woff2'),
    url('../fonts/GT-Walsheim-Medium/GT-Walsheim-Medium.woff') format('woff'),
    url('../fonts/GT-Walsheim-Medium/GT-Walsheim-Medium.ttf') format('truetype'),
    url('../fonts/GT-Walsheim-Medium/GT-Walsheim-Medium.eot?#iefix')
      format('embedded-opentype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Medium-Oblique/GT-Walsheim-Medium-Oblique.woff2')
      format('woff2'),
    url('../fonts/GT-Walsheim-Medium-Oblique/GT-Walsheim-Medium-Oblique.woff')
      format('woff'),
    url('../fonts/GT-Walsheim-Medium-Oblique/GT-Walsheim-Medium-Oblique.ttf')
      format('truetype'),
    url('../fonts/GT-Walsheim-Medium-Oblique/GT-Walsheim-Medium-Oblique.eot?#iefix')
      format('embedded-opentype');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Bold/GT-Walsheim-Bold.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Bold/GT-Walsheim-Bold.woff') format('woff'),
    url('../fonts/GT-Walsheim-Bold/GT-Walsheim-Bold.ttf') format('truetype'),
    url('../fonts/GT-Walsheim-Bold/GT-Walsheim-Bold.eot?#iefix')
      format('embedded-opentype');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Bold-Oblique/GT-Walsheim-Bold-Oblique.woff2')
      format('woff2'),
    url('../fonts/GT-Walsheim-Bold-Oblique/GT-Walsheim-Bold-Oblique.woff')
      format('woff'),
    url('../fonts/GT-Walsheim-Bold-Oblique/GT-Walsheim-Bold-Oblique.ttf')
      format('truetype'),
    url('../fonts/GT-Walsheim-Bold-Oblique/GT-Walsheim-Bold-Oblique.eot?#iefix')
      format('embedded-opentype');
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}

html,
body {
  font-size: 16px;
  touch-action: manipulation;
}

body,
body * > *:not(script):not(style) {
  box-sizing: border-box;
  font-family: 'GT-Walsheim', 微軟正黑體, 'Microsoft JhengHei',
    'Microsoft JhengHei UI', 'Microsoft YaHei', 'Microsoft YaHei UI',
    'PingFang TC', 'Lantinghei TC', 'Heiti TC', 'Apple LiGothic', 'PMingLiU',
    Arial, sans-serif;
  margin: 0;
  padding: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    font-family: 'GT-Walsheim', 'Microsoft YaHei', 'Microsoft YaHei UI',
      微軟正黑體, 'Microsoft JhengHei', 'Microsoft JhengHei UI', 'PingFang TC',
      'Lantinghei TC', 'Heiti TC', 'Apple LiGothic', 'PMingLiU', Arial,
      sans-serif;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 1.2rem;
    margin: 1.516rem 0 0.75rem 0;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.3rem;
    margin: 1.7rem 0 0.85rem 0;
  }
  h3 {
    font-size: 1.375rem;
    line-height: 1.3rem;
    margin: 1.8rem 0 0.9rem 0;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.4rem;
    margin: 2rem 0 1rem 0;
  }
  h5 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 2.2rem 0 1.1rem 0;
  }
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 2.5rem 0 1.25rem 0;
  }
}
